.Spin {
  width: 58%;
  margin: 0.1rem 0.1rem;
  position: relative;
  left: 0.2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .completionContainer {
    position: relative;
    height: 7%;
    width: 100%;
    margin-bottom: 3%;
    .completion {
      height: 20%;
      width: 70%;
      position: absolute;
      bottom: 0%;
      left: 2%;
    }

    .redBar {
      height: 20%;
      background: #9632ee;
      position: absolute;
      bottom: 0%;
      left: 2%;
      border-radius: 2px;
    }
    .completionReward {
      position: absolute;
      right: 6%;
      top: 29%;
      width: 20%;
      height: 100%;

      > p {
        color: var(--button_text_color);
        position: absolute;
        left: 50%;
        top: 30%;
        font-size: 0.18rem;
        font-family: refrigatorDeluxe;
        transform: translateX(-50%);
        text-align: center;
        width: 100%;
      }

      &.disabled {
        pointer-events: none;
        filter: grayscale(1);
      }
      > img {
        position: absolute;
        right: 12%;
        height: 61%;
        bottom: 24%;
      }
    }
  }

  &-box {
    height: 70%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    .spinBox {
      margin: auto;
      width: 1.55rem;
      height: 1.55rem;
      text-align: center;
      position: relative;
   
      &.blue {
        position: relative;
        &::before {
          background: var(--box_rare) center no-repeat;
          background-size: 110% 110%;
        }
      }

      &.orange {
        position: relative;
        &::before {
          background: var(--box_legendary) center no-repeat;
          background-size: 110% 110%;
        }
      }

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: var(--box_epic) center no-repeat;
        background-size: 110% 110%;
      }

      &.disabled .items,
      &.disabled::before {
        filter: grayscale(1);
      }

      .items {
        max-width: 60%;
        max-height: 60%;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }
      .target,
      .banned {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 83%;
        height: 83%;
        margin: auto;
        z-index: 1;
      }
      .del{
        width:81%;

      }
      .ban {
        background: var(--img_label_received) center no-repeat;
        background-size: 100% 40%;
        > span {
          position: absolute;
          top: 43%;
          left: 30%;
          transform: rotate(-8deg);
        }
      }
      .del {
        background: var(--img_label_delete) center no-repeat;
        background-size: 100% 100%;
        > span {
          position: absolute;
          top: 41%;
          left: 30%;
          transform: rotate(-37deg);
        }
      }
    }

    .centerBox {
      margin: auto;
      width: 3.5rem;
      height: 1.2rem;
      text-align: center;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .buttonSpin {
        width: 80%;
        height: 90%;
        background: var(--img_btn_spin) center no-repeat;
        background-size: 100% 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 0.03rem;
        &.disabled{
          pointer-events: none;
          filter: grayscale(1);
        }
        > span {
          font-size: 0.36rem;
          font-family: refrigatorDeluxe;
          color: white;
          > span {
            > img {
              height: 0.3rem;
              margin-left: 0.05rem;
            }

            > span {
              margin-left: 0.05rem;
            }
          }
        }
      }

      .buttonHapus {
        position: relative;
        width: 2rem;
        height: 0.5rem;
        background: var(--img_btn_delete) no-repeat center;
        background-size: 100% 100%;

        p {
          font-family: refrigatorDeluxe;
          position: absolute;
          font-size: 0.22rem;
          color: white;
          text-transform: uppercase;
          left: 50%;
          top: 50%;
          transform: translateX(-50%) translateY(-50%);
          width: 100%;
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .bottomButton {
    height: 6%;
    margin-top: 6%;
    display: flex;
    justify-content: space-evenly;

    > div {
      width: 22%;
      text-align: center;
      background: var(--img_btn_klaim) center no-repeat;
      background-size: 100% 100%;
      position: relative;

      > p {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        color: var(--button_text_color);
        font-family: refrigatorDeluxe;
      }

      &.disabled {
        filter: grayscale(1);
      }
    }
  }
}
