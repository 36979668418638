.Home {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.tippy-box{
    background-color:rgba(196, 56, 13, 0.705) !important;
    border: solid white 0.5px;
    color: white;
    font-family: refrigatorDeluxe;

    .float-box{
        font-size: 0.18rem;
        padding: 0;
    }
  }

.right-bar{
    width: 20%;
}