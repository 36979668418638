/* SETUP FOR KEEP ASPECT RATIO VIEWPORT */

html {
	top: 0;
	position: fixed;
	width: 100%;
	height: 100%;

	body {
		width: 100%;
		height: 100%;
		background-color: #000;
  }
}
#root {
  position: absolute;
  width: 100%;
  height: 100%;
  font-size: 0.22rem;
}

body{
  width: 100%;
  height: 100%;
  background-color: #000;
}
#Main {
  position: fixed;
  margin: 0 auto;
  top: 0 !important;
  right: 0;
  left: 0;
  bottom: 0;
  /* setup layout size */
  width: 13.34rem;
  height: 6.6rem;
  overflow: hidden;
  background-image: var(--img_bg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  &:before {
    content: '';
    position: fixed;
    z-index: -2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // background-color: #000;
    /* Setup background for handle space on left and right or top and bottom. */
  }

  .content-container {
    width: 100%;
    height: 100%;
    position: relative;
  }
}

/* Modal layout setup */
.react-responsive-modal-overlay{
  overflow: hidden;
  padding: unset !important;
  width: 100%;
  height: 6.6rem;
  align-items: center !important;
}

.react-responsive-modal-root {
	display: flex;
	align-items: center;
	width: 100%;
	height: 6.6rem;
}

.react-responsive-modal-modal {
	margin: 0 auto !important;
	max-width: none !important;
}

.react-responsive-modal-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 8.7rem;
  height: 5.2rem;
  padding: 0.5rem 0.5rem 0.3rem 0.55rem !important;
  position: relative;
  margin: 0 auto !important;
	max-width: none !important;
	display: flex;
	align-items: center;
	margin: 0 auto;
  font-size: 0.28rem;

}
/* END SETUP FOR KEEP ASPECT RATIO VIEWPORT */
