.overlay {
  .modals {
    background: var(--bg_popup) center no-repeat;
    background-size: 100% 100%;
    max-width: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    font-family: refrigatorDeluxe;

    ::-webkit-scrollbar {
      width: 0.7vmax;
      margin-left: 1vmin;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: white;
      border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #feb113;
      border-radius: 10px;
    }

    .buttonOption {
      position: absolute;
      bottom: 0.2rem;
      display: flex;
      justify-content: space-evenly;

      > div {
        height: 0.7rem;
        width: 1.8rem;
        text-align: center;
        background: var(--img_btn_default) center no-repeat;
        background-size: 100% 100%;
        position: relative;
        &.disabled {
          pointer-events: none;
          filter: grayscale(1);
        }
        > p {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translateX(-50%) translateY(-50%);
          color: white;
          font-family: refrigatorDeluxe;
        }

        > img {
          max-height: 100%;
          border-radius: 60px;
        }
      }
    }

    &--deletePrize {
      background: var(--bg_popup) center no-repeat;
      background-size: 100% 100%;
      justify-content: flex-start;

      .modalTitle {
        width: 80%;
        text-align: center;
        margin-top: 2%;

        > span {
          font-size: 0.36rem;
          color: #ffffff;
          font-weight: lighter;
          text-shadow: 1px 1px 2px #000000;

          > span {
            color: white;
            font-weight: bolder;
          }
        }
      }

      .prizeList {
        width: 80%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 0.1rem 0.5rem;
        margin: 0.15rem 0;

        .itemContainer {
          background-size: contain;
          position: relative;

          .itemName {
            width: 0.9rem;
            margin-top: 0.05rem;
            text-align: center;
            color: white;
            font-size: 0.18rem;
            display: flex;
            align-items: center;
            justify-content: center;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
        }

        .itemWrapper {
          width: 0.9rem;
          height: 0.9rem;
          margin: 1%;
          background: rgba($color: white, $alpha: 0.2);
          background-size: contain;
          position: relative;
          padding: 1%;

          .checkBox {
            background: var(--img_bg_check) center no-repeat;
            background-size: 90% 100%;
            position: absolute;
            width: 20%;
            height: 20%;
            right: 3.5%;
            top: 3%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 2.5%;
            z-index: 1;

            > img {
              max-width: 100%;
              max-height: 100%;
            }

            .tick {
              width: 100%;
              height: 100%;
              z-index: 2;
            }
          }

          > img {
            max-width: 70%;
            max-height: 100%;
            vertical-align: middle;
            position: absolute;
            top: 45%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }

    &--error {
      padding-bottom: 1rem !important;
      .errorTitle {
        height: 10%;
        margin-bottom: 10%;
        > span {
          color: white;
          font-size: 0.48rem;
        }
      }

      .errorMessage {
        > span {
          color: #ffffff;
        }
      }
    }

    &--prize {
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      .prizeTitle {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 15%;
        margin-top: 2%;
        text-align: center;

        > span {
          &:first-child {
            color: yellow;
            font-size: 0.64rem;
          }

          &:last-child {
            color: #ffffff;
            letter-spacing: 0.015rem;
          }
        }
      }

      .prizeImage {
        height: 25%;
        margin: 1.5% 0;
        padding: 2.5%;
        > img {
          max-height: 100%;
        }
      }

      .prizeName {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 1%;

        > span {
          position: relative;
          bottom: 0.3rem;
          color: #ffffff;
        }
      }
    }

    &--info {
      padding: 0;

      .infoContainer {
        height: 3rem;
        width: 100%;
      }

      .tabs-group {
        font-family: refrigatorDeluxe;
        font-size: 0.18rem;
        display: flex;
        justify-content: center;
        width: 100%;
        height: 0.25rem;

        .tab {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 21%;
          text-align: center;
          position: relative;

          .tandaseru {
            position: absolute;
            right: 5%;
            top: -65%;
            width: 0.3rem;
            height: 0.3rem;
          }
        }
      }
      .infoContent {
        font-size: 0.22rem;
        padding: 0 0.2rem;
        margin: 3% auto;
        height: 100%;
        width: 100%;
        color: white;
        overflow-y: auto;
        overflow-x: hidden;

        > ol {
          list-style-type: decimal;
          margin-top: 2%;
          width: 95%;
          padding-inline-start: 4%;

          > li {
            color: #ffffff;

            &:not(:last-child) {
              margin-bottom: 2.5%;
            }
          }
        }
      }
      .historyTitle {
        font-family: refrigatorDeluxe;
        font-size: 0.32rem;
        margin: 0.1rem 0;
        color: #ffe83f;
        text-align: center;
      }

      .historyList {
        margin-top: 2%;
        height: 90%;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 8% 0%;
        overflow-y: auto;
        padding: 0 4%;

        .itemWrapper {
          width: 20%;
          display: flex;
          flex-direction: column;
          align-items: center;

          .itemImage {
            width: 80%;
            margin-bottom: 0.1rem;
            padding: 17%;
            > img {
              max-width: 100%;
            }
          }

          .itemName {
            width: 100%;
            text-align: center;

            > span {
              color: #ffffff;
              font-size: 0.22rem;
            }
          }
        }
      }
    }

    &--consent {
      padding-bottom: 1rem !important;
      .consentTitle {
        margin-bottom: 2%;
        > span {
          text-align: center;
          font-size: 0.36rem;
          color: white;
        }
      }

      .consentContent {
        padding: 0 3%;
        margin: 1% 0;
        height: 78%;
        width: 100%;
        font-size: 0.22rem;
        color: white;
        line-height: 1.4;
        overflow-y: auto;
      }
    }
  }
}

.close-button {
  outline: none;
  cursor: pointer;
  position: absolute !important;
  top: 0.15rem !important;
  right: 0.05rem !important;
  background: var(--img_btn_close_popup) no-repeat center;
  background-size: 100% 100%;
  width: 0.3rem;
  height: 0.3rem;

  svg {
    display: none !important;
  }
}

.carousel-root {
  margin-top: 0.3rem;
  width: 90%;
  margin: 6% auto;
  position: static;
  .carousel.carousel-slider,
  .carousel {
    position: static;
  }
  .carousel .control-prev.control-arrow {
    left: 5%;
    top: 20%;
  }
  .carousel .control-next.control-arrow {
    right: 5%;
    top: 20%;
  }
  .legend {
    display: none;
  }
}
