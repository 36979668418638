.Sidebar {
  width: 22%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: 0%;
  position: relative;

  .img-kv {
    position: absolute;
    top: 25%;
    height: 90%;
    right: -15%;
  }
  .event-title {
    width: 75%;
    margin-top: 5%;
    margin-bottom: -1%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > img {
      max-width: 100%;
    }

    .codm-logo {
      width: 1rem;
      margin-bottom: 0.1rem;
    }
  }

  .nickname,
  .balance {
    font-family: refrigatorDeluxe;
    color: #ffffff;
    text-align: center;

    > span {
      font-size: 0.24rem;
      vertical-align: middle;

      > img {
        vertical-align: middle;
        height: 0.2rem;
      }
    }
  }
}
