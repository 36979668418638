.Milestone {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    height: 100%;

    .bar {
        margin-top: 0.9rem;
        width: 18%;
        height: 74%;
        border-radius: 1rem;
        position: relative;
        .redBar {
            width: 19%;
            background: var(--bar_right_fill_color);
            position: absolute;
            bottom: 2.5%;
            left: 41%;
            box-shadow: #faff6e 0px 0px 8px 0px;
        }

        .milestonePoint {
            width: 100%;
            height: 83%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            position: relative;

            >div {
                width: 100%;

                >img {
                    max-width: 100%;
                }
            }
        }
    }

    .itemMilestone {
        margin-top: 0.9rem;
        margin-left: 0.25rem;
        height: 67%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        gap: 0.2rem;

        .itemWrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 30%;

            .buttonClaim{
                position: relative;
                height: 0.25rem;
                width: 1rem;
                top: 0.05rem;
     
                > p {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translateX(-50%) translateY(-50%);
                    font-size: 0.16rem;
                    color:var(--button_text_color);
                    font-family: refrigatorDeluxe;
                  }

                  &.disabled {
                    filter: grayscale(1);
                    pointer-events: none;
                }
            }

          

            >div {
                text-align: center;

                &.spinCount {
                    color: white;
                    font-family: refrigatorDeluxe;
                    font-style: italic;
                    font-size: 0.18rem;
                    position: relative;
                    bottom: 0.05rem;
                }

                &.purpleCrate {
                    height: 0.8rem;
                    width: 0.8rem;
                }

                >img {
                    max-width: 80%;
                    vertical-align: middle;
                }
            }
        }
    }
}