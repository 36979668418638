@import 'reset';
@import 'layout';
$cdn: 'https://cdngarenanow-a.akamaihd.net/gstaticid/CODM/deletionluckydraw3/';

@font-face {
    font-family: refrigatorDeluxe;
    src: url('https://cdngarenanow-a.akamaihd.net/webid/CODM/fonts/REFRIGERATORDELUXE-BOLD.TTF');
}

@font-face {
    font-family: psl;
    src: url('https://cdngarenanow-a.akamaihd.net/webid/CODM/fonts/PSL174pro.ttf');
}

* {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

img{
    -webkit-user-drag:none;
    pointer-events: none;
}

//LOCALIZATION

[data-lang="TW"] {    
    .Spin-box .centerBox .buttonHapus p{
        font-size: 0.16rem;
    }
}

[data-lang="TH"]{
    font-family: psl;
    
    .tippy-box{
        font-family: psl;
    }
    .Milestone .itemMilestone .itemWrapper .buttonClaim p{
        font-family: psl;
    }
    .Milestone .itemMilestone .itemWrapper .spinCount{
        font-family: psl;
    }
    .Spin .completionContainer .completionReward > p{
        font-family: psl;
    }
    .Sidebar .nickname, .Sidebar .balance {
        font-family: psl;
    }
    .Spin .bottomButton > div > p{
        font-family: psl;
    }
    .Spin-box .centerBox .buttonHapus p{
        font-family: psl;
    }
    .Spin-box .centerBox .buttonSpin {
        span {
            font-family: psl;
            display: flex;
            align-items: center;
        }
    }
    .overlay .modals--info .tabs-group{
        font-family: psl;
    }
    .overlay .modals{
        font-family: psl;
        .historyTitle {
            font-family: psl;
        }
        .buttonOption {
            p {
                font-family: psl;
            }
        }
    }
}